<template>
  <div class="auther-blog-container" :style="noShow ? 'border: none' : ''">
    <div class="item" v-for="v in listData.list" :key="v.articleId">
      <div class="title" @click="goDetails(v)">{{ v.title }}</div>
      <div class="date">{{ v.createTime }}</div>
    </div>
    <div class="no-article" v-show="noShow">
      <img :src="require('@/assets/qietu/article.png')" alt="">
      <p>暂无文章</p>
    </div>
    <Page 
      v-show="+listData.total > 20"
      :total="+listData.total"
      :current="listData.pageNum"
      show-total
      :page-size="20"
      :styles="{textAlign: 'center',marginTop: '20px'}"
      @on-change="pageChange">
      {{'共 '+(listData.total ? listData.total : 0)+' 篇'}}
    </Page>
    <Spin size="large" fix v-show="spinShow"></Spin>
  </div>
</template>

<script>
export default {
  data() {
    return {
      listData: {},
      params: {
        userId: this.$route.query.id,
        pageNum: 1,
        pageSize: 20
      },
      spinShow: false
    };
  },
  computed: {
    noShow() {
      return this.listData.list && this.listData.list.length === 0
    }
  },
  created() {
    this.getIfList(this.params);
  },
  methods: {
    goDetails(item) {
      let url = this.$router.resolve({
        path: "/informationdetail",
        query: {
          id: item.articleId,
        },
      });
      window.open(url.href, "black");
    },
    async getIfList(data) {
      this.spinShow = true
      const resp = await this.$apis.autherServe.getIfList(data);
      this.listData = resp.data;
      this.spinShow = false
    },
    pageChange(page) {
      this.params.pageNum = +page
      this.getIfList(this.params);
    },
  },
};
</script>

<style lang="scss" scoped>
.auther-blog-container {
  position: relative;
  margin-bottom: 111px;
  padding: 30px;
  min-height: 300px;
  border: 1px dotted #999999;
  border-radius: 12px;
  width: 1040px;
  .no-article {
    padding-top: 20px;
    text-align: center;
    font-size: 16px;
    color: #999999;
    img {
        width: 483px;
        height: 304px;
    }
  }
  .item {
    width: 100%;
    height: 66px;
    border-bottom: 1px dashed #d6d6d6;
    margin-bottom: 25px;
    &:last-child {
      border-bottom: none;
    }
    .title {
      font-size: 20px;
      color: #333333;
      cursor: pointer;
    }
    .date {
      font-size: 14px;
      color: #999999;
      margin-top: 5px;
    }
  }
}
</style>
